<template>
  <div class="ForgetPassword" v-fixed>
    <div class="topbar">
      <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title">{{ $t("密碼找回") }}</div>
    </div>

    <div class="content" @click="$router.push('/FindByAccountCode')">
      <div>{{ $t("帳號憑證找回") }}</div>
      <v-icon size="18" color="#848484">mdi-chevron-right</v-icon>
    </div>
    <div class="hr"></div>
  </div>
</template>

<script>
export default {
  directives: {
    fixed: {
      // inserted 被绑定元素插入父节点时调用
      inserted() {
        let scrollTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        document.body.style.cssText +=
          "position:fixed;width:100%;top:-" + scrollTop + "px;";
      },
      // unbind 指令与元素解绑时调用
      unbind() {
        let body = document.body;
        body.style.position = "";
        let top = body.style.top;
        document.body.scrollTop = document.documentElement.scrollTop =
          -parseInt(top);
        body.style.top = "";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.ForgetPassword {
  padding-top: 46px;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    text-align: left;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  .content {
    font-size: 14px;
    margin: 0 28px;
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    color: #485573;
  }
  .hr{
    margin: 12px 28px 0;
    border: 0.5px solid #95B6C7;
  }
}
</style>
